import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Fade,
    FormControl,
    InputAdornment,
    InputLabel,
    LinearProgress,
    MenuItem,
    Modal,
    Select,
    styled,
    TextField,
    Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import React, { useEffect, useState } from 'react';
import { useAuth, useSnackBar } from '../../../hooks';
import { EmployeeNotificationRequiredDetails, EmployerCompany, NotificationType } from '../../../@types';
import Grid from '@mui/material/Unstable_Grid2';
import { getEnumKeyByValue } from '../../../utils';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { NotificationStatistics } from '../../Notification';
import { PANNON_BUSZRENT_DAILY_WAGE, WORKFORCE_COMPANY_DAILY_WAGE } from '../../../consts/economicData';

const EFO_API_ENDPOINT = process.env.REACT_APP_EFO_API_ENDPOINT;

const EFOCard = styled(Card)`
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 400px;
    background-color: white;
    box-shadow: 2px 2px;
    padding-bottom: 14px;

    @media (max-width: 900px) {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translate(0%, 0%);
        width: 100%;
    }
`;

type NotifyEmployeeModalProps = {
    open: boolean;
    onClose: () => void;
    isUnregister: boolean;
    employee?: EmployeeNotificationRequiredDetails;
    date?: number;
    consecutiveDays?: number;
    company: keyof typeof EmployerCompany;
    externalWage?: number;
};

const NotifyEmployeeModal: React.FC<NotifyEmployeeModalProps> = ({
    open,
    onClose,
    isUnregister,
    employee,
    date,
    consecutiveDays,
    company: selectedCompany,
    externalWage,
}) => {
    const [from, setFrom] = useState<Date | null>(new Date());
    const [numberOfDays, setNumberOfDays] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [viewId, setViewId] = useState<number>(1);
    const [wage, setWage] = useState<number>(PANNON_BUSZRENT_DAILY_WAGE);

    const [company, setCompany] = useState(getEnumKeyByValue(EmployerCompany, EmployerCompany.PANNON_BUSZ_RENT));
    const [notificationType, setNotificationType] = useState(getEnumKeyByValue(NotificationType, isUnregister ? NotificationType.T : NotificationType.U));
    const { user } = useAuth();
    const { showResponseError, showError, showSuccess, snackBar } = useSnackBar();

    useEffect(() => {
        setNotificationType(getEnumKeyByValue(NotificationType, isUnregister ? NotificationType.T : NotificationType.U));
        if (date !== undefined) {
            setFrom(new Date(date));
        }
        setCompany(selectedCompany as keyof typeof EmployerCompany);
    }, [isUnregister, date, selectedCompany]);

    useEffect(() => {
        if (externalWage) return;
        setWage(company && company === 'PANNON_BUSZ_RENT' ? PANNON_BUSZRENT_DAILY_WAGE : WORKFORCE_COMPANY_DAILY_WAGE);
    }, [company]);

    useEffect(() => {
        if (externalWage) {
            setWage(externalWage);
        }
    }, [externalWage]);

    const handleSendNotification = async () => {
        if (loading || employee === undefined) {
            return;
        }
        if (!from || !user) {
            onClose();
            return;
        }

        try {
            setLoading(true);
            const { legalName, taxNumber, healthCareNumber } = employee;

            const notificationData = {
                name: legalName,
                taxNumber,
                healthCareNumber,
                notificationType,
                from,
                numberOfDays,
                wage,
            };

            const response = await fetch(`${EFO_API_ENDPOINT}/notification/`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.accessToken}`,
                },
                body: JSON.stringify({
                    createdBy: user.name,
                    company,
                    data: [notificationData],
                }),
            });

            if (response.status === 200) {
                showSuccess(
                    <Typography paragraph m={0} p={0}>
                        A bejelentést rögzítettük
                    </Typography>
                );
                setLoading(false);
                setViewId(Math.random());
                onClose();
                return;
            }
            await showResponseError(response);
            setLoading(false);
        } catch (err: any) {
            showError(<Typography>{err.message}</Typography>);
        }
    };

    return (
        <>
            {snackBar()}
            <Modal open={open}>
                <Fade in={open}>
                    <EFOCard>
                        {loading && <LinearProgress />}
                        <CardContent sx={{ p: 2 }}>
                            <Grid container pb={4}>
                                <Typography variant='h5'>{employee?.legalName || ''} alkalmi bejelentése</Typography>
                            </Grid>
                            <Grid container justifyContent={'center'}>
                                <Grid xs={6} sm={6} md={'auto'} pr={1}>
                                    <FormControl margin={'dense'}>
                                        <InputLabel id=''>Típusa</InputLabel>
                                        <Select
                                            sx={{ textAlign: 'center' }}
                                            variant={'standard'}
                                            value={notificationType}
                                            disabled={isUnregister}
                                            fullWidth={true}
                                            onChange={e => setNotificationType(e.target.value as NotificationType)}>
                                            {Object.keys(NotificationType).map((value, index) => (
                                                <MenuItem value={value} key={index}>
                                                    {value === 'U' ? (
                                                        <AddCircleOutlineIcon sx={{ fontSize: 14, paddingRight: 1, paddingBottom: 0 }} />
                                                    ) : (
                                                        <RemoveCircleOutlineIcon sx={{ fontSize: 14, paddingRight: 1, paddingBottom: 0 }} />
                                                    )}
                                                    {NotificationType[value as keyof typeof NotificationType]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={6} sm={3} md={'auto'} py={1} pr={1}>
                                    <DesktopDatePicker
                                        label='Kezdő dátum'
                                        format='yyyy.MM.dd.'
                                        value={from}
                                        disabled={isUnregister}
                                        minDate={new Date()}
                                        onChange={date => setFrom(date)}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid xs={2} sm={3} md={'auto'} pr={1}>
                                    <FormControl fullWidth margin={'dense'}>
                                        <InputLabel sx={{ textOverflow: 'unset' }}>Napok</InputLabel>
                                        <Select
                                            sx={{ textAlign: 'center' }}
                                            variant={'standard'}
                                            value={numberOfDays}
                                            onChange={e => setNumberOfDays(e.target.value as number)}>
                                            {[...new Array(isUnregister ? (consecutiveDays || 0) + 1 : 5).fill(1)].map((value, idx) => (
                                                <MenuItem value={idx + 1} key={idx}>
                                                    {idx + 1}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {isUnregister ? null : (
                                    <Grid xs={4} md={'auto'} my={0} pr={1}>
                                        <TextField
                                            fullWidth
                                            variant='standard'
                                            label={'Összeg'}
                                            disabled={isUnregister}
                                            value={wage}
                                            onChange={e => {
                                                const value = Number(e.target.value);
                                                if (!isNaN(value)) {
                                                    setWage(value);
                                                }
                                            }}
                                            sx={{
                                                maxWidth: 80,
                                                pt: 1,
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position='start'>Ft</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                )}

                                <Grid xs={6} md={'auto'}>
                                    <FormControl margin={'dense'}>
                                        <InputLabel id=''>Cég</InputLabel>
                                        <Select
                                            sx={{ textAlign: 'center', width: 160 }}
                                            variant={'standard'}
                                            value={company}
                                            disabled={isUnregister}
                                            onChange={e => setCompany(e.target.value as EmployerCompany)}>
                                            {Object.keys(EmployerCompany).map((value, index) => (
                                                <MenuItem value={value} key={index}>
                                                    {EmployerCompany[value as keyof typeof EmployerCompany]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {employee && !isUnregister && (
                                <NotificationStatistics taxNumber={employee.taxNumber} date={from?.toDateString() || new Date().toDateString()} />
                            )}
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'space-between' }}>
                            <Button
                                sx={{ mx: 1, borderRadius: 50 }}
                                variant={'contained'}
                                color={'info'}
                                onClick={() => onClose()}
                                startIcon={<ArrowBackIosNewTwoToneIcon />}>
                                mégsem
                            </Button>
                            <Button
                                sx={{ mx: 1, borderRadius: 50 }}
                                variant={'contained'}
                                color={'warning'}
                                onClick={handleSendNotification}
                                endIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <SendIcon />}>
                                {isUnregister ? 'kijelentés' : 'bejelentés'} beküldése
                            </Button>
                        </CardActions>
                    </EFOCard>
                </Fade>
            </Modal>
        </>
    );
};
export { NotifyEmployeeModal };
