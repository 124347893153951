export const TAX_AMOUNT = 4400;
export const YEAR = 2025;
export const WORKFORCE_COMPANY_DAILY_WAGE = 15000;
export const PANNON_BUSZRENT_DAILY_WAGE = 20800;
export const GUARANTEED_WAGE_MONTHLY = 348800;
export const GUARANTEED_WAGE_WEEKLY = 80190;
export const GUARANTEED_WAGE_DAILY = 16040;
export const GUARANTEED_WAGE_HOURLY = 2005;
export const MINIMUM_WAGE_MONTHLY = 290800;
export const MINIMUM_WAGE_WEEKLY = 66860;
export const MINIMUM_WAGE_DAILY = 13380;
export const MINIMUM_WAGE_HOURLY = 1672;
export const TEMPORARY_WORK_WAGE = 1000;